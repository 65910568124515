import React, { useState } from 'react'
import './Service.css'
import aws from '../../../images/Group 660.png'
import gcs from '../../../images/Group 663.png'
import azure from '../../../images/Group 670.png'
import goDaddy from '../../../images/Group 664.png'
import vps from '../../../images/Group 665.png'
import host from '../../../images/Group 666.png'
import ssl from '../../../images/Group 667.png'
import domain from '../../../images/Group 668.png'
import firebase from '../../../images/Group 669.png'
import Fade from 'react-reveal/Fade'
import Flip from 'react-reveal/Flip'
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Roll from 'react-reveal/Roll';


export default function Service({ service, themeColor, ...props }) {

  return (
    <section className="maintenance">
      <div className="dynamic-image img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="dynamic-image-second img-fluid d-none d-lg-block d-xl-block" style={{ backgroundColor: themeColor }}></div>
      <div className="container">
        <h2 className="main-heading-title text-center mb-0">{service.title}</h2>
        <div className="d-flex justify-content-center">
          <h2 className="line"><span><div className="square" style={{ background: themeColor }}></div></span></h2>
        </div>
        <div className="row maintenance-container">
          {service.ServiceType.map((p, i) => (
            <div className={`"col-12 col-md-6 col-lg-4 product`}>
              <div className="card">
                <div className="card-body">
                  <Flip top duration={2000} delay={i * 100}>
                    {/*<img src={p.serviceImage.fluid.src} className="img-fluid mb-0" alt="icon" />*/}
                  </Flip>
                  <Slide top duration={2000} delay={i * 150}>
                    <div className="maintenance-line"></div>
                  </Slide>
                  <Slide top duration={2000} delay={i * 150}>
                    <h2 className="maintenance-card-title">{p.title}</h2>
                  </Slide>
                  <Slide bottom duration={2000} delay={i * 150}>
                    <p className="maintenance-description">{p.description?.description}</p>
                  </Slide>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
